$header-color: #000C12 !default;

//変数定義用scss
$primary-color: #4A90E2 !default;
$primary-light-color: #83c0ff !default;
$primary-dark-color: #0063b0  !default;

$secondary-color: #D0021B !default;
$secondary-light-color: #ff5144 !default;
$secondary-dark-color: #960000 !default;

$primary-text-color: #ffffff !default;
$secondary-text-color: rgba(0,0,0,0.38) !default;


$background-white: #FAFAFA !default;
$background-cream-transparent: #f9f9f9 !default;
//RGBA(223, 212, 192, 0.2)*/
$background-blue-gray-transparent: rgba(178, 202, 211,0.5) !default;


//12,24,56,64 を利用する想定

//gutter
$gutter: 24px !default;
$gutter-small:  $gutter / 2 !default;
$gutter-large: $gutter * 1.5 !default;

//header
$header-height: 64px;
$header-height-min: 56px;

$footer-height : 40px;


/**********************
  MediaQueries Snippets
  from https://www.nxworld.net/tips/sass-media-queries-mixin.html
 */
$mq-breakpoints: (
        'xs': 360px,//スマホ
        'sm' :768px,//タブレット
        'md': 992px,//PC
        'large' : 1024px,
        'xlarge': 1280px,
);
@mixin mq($breakpoint: medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule == max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}
