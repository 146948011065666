@charset "UTF-8";
/**********************
  MediaQueries Snippets
  from https://www.nxworld.net/tips/sass-media-queries-mixin.html
 */
body {
  background-image: url("../assets/images/bg-default.svg");
  background-size: 40%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom; }

header {
  position: fixed;
  top: 0;
  z-index: 40;
  width: 100%;
  height: 64px;
  background: #000C12; }
  @media screen and (max-width: 991px) {
    header {
      height: 56px; } }
  header div {
    display: flex;
    flex-direction: row; }
    header div #header-logo {
      margin-left: 24px;
      flex-grow: 1; }
      header div #header-logo #header-logo-img {
        padding: 24px;
        height: 16px;
        width: 200px;
        background-image: url("../assets/images/ist-logo-01-w.svg");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat; }
        @media screen and (max-width: 991px) {
          header div #header-logo #header-logo-img {
            height: 56px;
            display: none; } }
      @media screen and (max-width: 991px) {
        header div #header-logo {
          height: 56px; } }
    header div #header-title {
      position: absolute;
      height: 100%;
      flex-grow: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      align-items: center;
      justify-content: center; }
      header div #header-title span {
        font-size: 1.3em;
        color: white; }
      @media screen and (max-width: 991px) {
        header div #header-title {
          height: 56px;
          display: none; } }
    header div #header-action-left {
      align-items: center;
      margin: 2px 0; }
      header div #header-action-left button {
        height: auto;
        margin-left: 12px; }
    header div #header-action {
      align-items: center;
      margin: 2px 0; }
      header div #header-action button {
        height: auto;
        margin-right: 12px; }

.button {
  cursor: pointer !important; }

.common-radio-form-control {
  height: 80px !important;
  margin-top: 16px !important;
  margin-bottom: 8px !important; }

.common-radio-form-label {
  font-size: 0.76rem !important;
  margin-left: 12px; }

.common-radio-group {
  margin-left: 12px; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.Container {
  padding-top: calc(64px + 12px);
  margin-right: 12px;
  margin-left: 12px; }
  .Container.with-footer {
    min-height: calc(100vh - 64px - 24px - 40px); }
  .Container.with-stepper {
    padding-top: 64px; }

.Footer {
  max-height: 40px;
  overflow: hidden;
  text-align: right;
  margin-right: 12px;
  margin-left: 12px; }
  .Footer span {
    color: rgba(0, 0, 0, 0.38);
    font-size: 12px; }

.selectable {
  cursor: pointer; }

/* ============= Paper ================*/
.paper {
  width: 100%;
  max-width: 500px;
  margin: 24px;
  padding: 24px; }
  @media screen and (max-width: 991px) {
    .paper {
      width: 100%;
      box-shadow: none !important;
      margin: 0;
      padding: 0; } }

/* ============= Login ================*/
.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .Login .paper-login {
    width: 100%;
    max-width: 500px;
    margin: 24px;
    padding: 24px; }
    @media screen and (max-width: 991px) {
      .Login .paper-login {
        width: 100%;
        box-shadow: none;
        margin: 0;
        padding: 0;
        margin-bottom: 24px; } }
  .Login .paper-information {
    width: 100%;
    max-width: 500px;
    margin: 24px;
    padding: 24px;
    background-color: rgba(178, 202, 211, 0.5); }
    @media screen and (max-width: 991px) {
      .Login .paper-information {
        width: 100%;
        box-shadow: none;
        margin: 0;
        margin-bottom: 24px;
        padding: 12px; } }
  .Login .help-text {
    margin: 12px; }

.login-help-actions {
  padding: 0 24px 24px;
  margin: 0; }
  .login-help-actions button {
    min-width: 110px; }

/* ============= Loading Wrapper ===============*/
.loading-wrapper {
  min-width: 100vw;
  position: fixed;
  min-height: calc(100vh - 64px);
  top: 64px; }
  @media screen and (max-width: 991px) {
    .loading-wrapper {
      min-height: calc(100vh - 56px);
      top: 56px; } }
  .loading-wrapper.active {
    z-index: 999; }
  .loading-wrapper.fullscreen {
    min-height: calc(100vh);
    top: 0; }

._loading_overlay_wrapper {
  position: initial !important; }

/* ============= Logo Wrapper ================*/
.logo-wrapper {
  padding: 24px;
  margin-bottom: 24px; }
  .logo-wrapper .logo-image {
    width: 100%;
    height: 70px;
    background-image: url("../assets/images/ist-logo-02-c.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .logo-wrapper .logo-image {
      background-image: url("../assets/images/ist-logo-02-c.svg"); } }

.name-wrapper {
  display: flex;
  height: 24px;
  padding-top: 12px;
  justify-content: center;
  align-items: center; }

/* ============= MainMenu ================*/
.main-menu {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 991px) {
    .main-menu {
      display: block;
      max-width: 500px; } }
  @media screen and (max-width: 991px) {
    .main-menu .paper-logo {
      box-shadow: none; } }
  .main-menu .paper-information {
    padding: 24px;
    margin-bottom: 24px;
    background-color: rgba(178, 202, 211, 0.5); }
    @media screen and (max-width: 991px) {
      .main-menu .paper-information {
        box-shadow: none;
        margin: 0;
        margin-bottom: 24px;
        padding: 12px; } }
  .main-menu .logo-info-wrapper {
    width: 100%;
    max-width: 500px;
    padding: 24px; }
    @media screen and (max-width: 991px) {
      .main-menu .logo-info-wrapper {
        width: 100%;
        box-shadow: none;
        margin: 0;
        padding: 0; } }
  .main-menu .menu-wrapper {
    width: 100%;
    max-width: 500px;
    padding: 24px; }
    @media screen and (max-width: 991px) {
      .main-menu .menu-wrapper {
        box-shadow: none;
        padding: 0; } }
    .main-menu .menu-wrapper .menu-check-in {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px; }
      .main-menu .menu-wrapper .menu-check-in .check-in-button {
        width: calc(50%  - 12px);
        margin-right: 12px;
        padding: 24px;
        background-color: #4A90E2; }
      .main-menu .menu-wrapper .menu-check-in .check-out-button {
        width: calc(50%  - 12px);
        margin-left: 12px;
        padding: 24px;
        background-color: #4A90E2; }
      .main-menu .menu-wrapper .menu-check-in .button .icon {
        margin: auto;
        width: 84px;
        height: 84px;
        margin-bottom: 24px;
        color: white; }
        .main-menu .menu-wrapper .menu-check-in .button .icon.sit {
          background-image: url("../assets/images/icon-user-sit.svg") !important;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat; }
        .main-menu .menu-wrapper .menu-check-in .button .icon.stand {
          background-image: url("../assets/images/icon-user-stand.svg") !important;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat; }
      .main-menu .menu-wrapper .menu-check-in .button .title {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff; }
      .main-menu .menu-wrapper .menu-check-in .button:hover, .main-menu .menu-wrapper .menu-check-in .button:active {
        background-color: #83c0ff; }
    .main-menu .menu-wrapper .menu-common .common-button {
      display: flex;
      height: 76px;
      width: 100%;
      margin-bottom: 24px;
      background-color: #4A90E2; }
    .main-menu .menu-wrapper .menu-common .button .icon {
      margin: 24px;
      width: 32px;
      height: 32px;
      font-size: 32px;
      line-height: 32px;
      color: white; }
    .main-menu .menu-wrapper .menu-common .button .title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff; }
    .main-menu .menu-wrapper .menu-common .button:hover, .main-menu .menu-wrapper .menu-common .button:active {
      background-color: #83c0ff; }

/* =============Passcode ===============*/
.passcode-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

/* =============KeyPad ===============*/
.keypad-box-common {
  width: 330px;
  margin: 5px auto;
  padding-bottom: 10px; }
  .keypad-box-common .keypad {
    position: relative; }
    .keypad-box-common .keypad .keypad-keys {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      /*
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      */ }
      .keypad-box-common .keypad .keypad-keys .keypad-key {
        touch-action: manipulation;
        width: 90px;
        height: 90px;
        color: #555555;
        text-align: center;
        line-height: 90px;
        margin: 10px;
        font-size: 30px; }
        .keypad-box-common .keypad .keypad-keys .keypad-key div {
          touch-action: manipulation; }
        .keypad-box-common .keypad .keypad-keys .keypad-key.keypad-key-zero {
          height: 90px;
          width: auto;
          grid-column: 1 / span 3; }
    .keypad-box-common .keypad .keypad-menu {
      position: absolute;
      display: flex;
      width: 90px;
      top: 0;
      right: -110px;
      padding: 10px; }
      .keypad-box-common .keypad .keypad-menu .keypad-menu-key {
        touch-action: manipulation;
        width: 90px;
        height: 90px;
        color: #555555;
        text-align: center;
        line-height: 90px;
        font-size: 20px; }
        .keypad-box-common .keypad .keypad-menu .keypad-menu-key div {
          color: white;
          background-color: #CE0820;
          touch-action: manipulation; }
          .keypad-box-common .keypad .keypad-menu .keypad-menu-key div:hover {
            background-color: darkred; }

.passcode-input-view {
  display: flex;
  height: 55px;
  margin-bottom: 12px; }
  .passcode-input-view .left {
    align-self: flex-end; }
  .passcode-input-view .number {
    flex-grow: 1;
    text-align: center;
    padding-top: 0;
    font-size: 40px;
    color: #888888;
    letter-spacing: 20px;
    margin-left: 20px; }
  .passcode-input-view .right {
    align-self: flex-end; }

/* ===========NavigationMessage for iPad ===============*/
.navigation-message {
  text-align: center;
  margin: 5px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.38); }

.navigation-message-big {
  text-align: center;
  margin: 100px 5px;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.38); }

/* ============SignUp==================*/
.sign-up-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

/* =============Reserve ===============*/
.reserve-form, .reserve-input, .reserve-complete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* ============Reserve FORM 予約検索フォーム====================*/
  /* ==========Reserve Input 予約最終入力===============*/
  /*========= Reserve Complete */ }
  .reserve-form .reserve-form-select-input, .reserve-input .reserve-form-select-input, .reserve-complete .reserve-form-select-input {
    display: block;
    text-align: left; }
  .reserve-form .reserve-form-date, .reserve-input .reserve-form-date, .reserve-complete .reserve-form-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .reserve-form .reserve-form-date #reserve-form-date-start, .reserve-form .reserve-form-date #reserve-form-date-end, .reserve-input .reserve-form-date #reserve-form-date-start, .reserve-input .reserve-form-date #reserve-form-date-end, .reserve-complete .reserve-form-date #reserve-form-date-start, .reserve-complete .reserve-form-date #reserve-form-date-end {
      width: 100%; }
  .reserve-form .select-multi, .reserve-input .select-multi, .reserve-complete .select-multi {
    display: flex;
    flex-wrap: wrap; }
  .reserve-form .select-chip, .reserve-input .select-chip, .reserve-complete .select-chip {
    margin: 6px; }
  .reserve-form form, .reserve-input form, .reserve-complete form {
    width: 100%; }
  .reserve-form .paper-reserve-input, .reserve-input .paper-reserve-input, .reserve-complete .paper-reserve-input {
    padding: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: #f9f9f9; }
    @media screen and (max-width: 991px) {
      .reserve-form .paper-reserve-input, .reserve-input .paper-reserve-input, .reserve-complete .paper-reserve-input {
        box-shadow: none;
        margin: 0;
        margin-bottom: 12px;
        padding: 12px; } }
  .reserve-form .icon-wrapper, .reserve-input .icon-wrapper, .reserve-complete .icon-wrapper {
    text-align: center; }
  .reserve-form .inner, .reserve-input .inner, .reserve-complete .inner {
    padding-top: 12px;
    padding-bottom: 12px; }
  .reserve-form .reserve-subject-cell, .reserve-input .reserve-subject-cell, .reserve-complete .reserve-subject-cell {
    margin-bottom: 12px; }
  .reserve-form .wide-paper, .reserve-input .wide-paper, .reserve-complete .wide-paper {
    max-width: 800px; }
  .reserve-form .step, .reserve-input .step, .reserve-complete .step {
    position: relative; }
  .reserve-form #step0, .reserve-form #step1, .reserve-form #step2, .reserve-input #step0, .reserve-input #step1, .reserve-input #step2, .reserve-complete #step0, .reserve-complete #step1, .reserve-complete #step2 {
    position: absolute;
    top: calc(-64px - 10px);
    height: calc(64px + 10px);
    visibility: hidden; }
    @media screen and (max-width: 991px) {
      .reserve-form #step0, .reserve-form #step1, .reserve-form #step2, .reserve-input #step0, .reserve-input #step1, .reserve-input #step2, .reserve-complete #step0, .reserve-complete #step1, .reserve-complete #step2 {
        top: calc(-56px - 10px);
        height: calc(56px + 10px); } }

/* ============Reserve TABLE 予約　リスト一覧====================== */
.reserve-list ul {
  list-style-type: none;
  padding: 0; }

.reserve-list .paper-row {
  padding: 12px 0;
  margin: 24px; }
  .reserve-list .paper-row .row-title {
    margin: 0 24px; }

.reserve-list .reserve-card {
  margin: 24px;
  margin-bottom: 12px; }
  .reserve-list .reserve-card .reserve-card-header {
    padding-top: 24px;
    padding-bottom: 12px; }
  .reserve-list .reserve-card .reserve-card-content .inner {
    padding-top: 12px; }
  .reserve-list .reserve-card .reserve-card-action {
    padding: 12px 24px 24px 24px;
    display: flex;
    justify-content: space-between; }
    .reserve-list .reserve-card .reserve-card-action .detail-button {
      margin-left: auto; }

/* ============Ticket 一覧====================== */
.ticket-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }
  @media screen and (max-width: 1279px) {
    .ticket-list {
      grid-template-columns: 1fr 1fr; } }
  @media screen and (max-width: 991px) {
    .ticket-list {
      grid-template-columns: 1fr; } }

.ticket {
  margin: 24px;
  margin-bottom: 12px; }
  .ticket .ticket-header {
    padding-top: 24px;
    padding-bottom: 12px;
    background-color: lightgray; }
  .ticket .ticket-content {
    padding: 24px; }
    .ticket .ticket-content .item-container {
      padding: 12px 0;
      font-size: 36px;
      line-height: 36px; }
    .ticket .ticket-content .item-resource-container {
      text-align: right; }
    .ticket .ticket-content .time-container {
      padding: 12px 0;
      display: flex;
      justify-content: space-between; }
      .ticket .ticket-content .time-container > div {
        display: flex;
        width: 48%;
        flex-direction: column;
        justify-content: space-between; }
      .ticket .ticket-content .time-container .time-heading {
        height: 100%;
        width: 100%;
        text-align: center;
        background-color: gray;
        display: flex;
        justify-content: left;
        align-items: start; }
        .ticket .ticket-content .time-container .time-heading span {
          margin: 0 12px;
          color: white; }
      .ticket .ticket-content .time-container .day-label {
        margin: 0 12px;
        font-size: 16px; }
      .ticket .ticket-content .time-container .time-label {
        margin: 0 12px;
        font-size: 24px; }
  .ticket .ticket-action {
    padding: 12px 24px 24px 24px;
    display: flex;
    justify-content: space-between; }
    .ticket .ticket-action .detail-button {
      margin-left: auto; }
      .ticket .ticket-action .detail-button .icon {
        margin-right: 12px !important; }

/* ============ vis.js timeline ====================*/
.vis-item {
  background-color: #ACCA3399 !important;
  color: #00000095 !important; }

.vis-item.vis-range {
  top: 24px !important;
  border: 0;
  color: #ffffff !important;
  background-color: #00000065 !important; }

.vis-item.vis-background {
  background-color: #ACCA3399 !important; }

.vis-item.vis-background.positive {
  background-color: #ACCA3399 !important; }

.vis-item.vis-background.negative {
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.3) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: 5px 5px;
  background-attachment: fixed; }

/* =========== DatePickerDialog NumberPickerDialog===========*/
.date-picker-dialog-container, .number-picker-dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .date-picker-dialog-container .date-picker-dialog, .date-picker-dialog-container .number-picker-dialog, .number-picker-dialog-container .date-picker-dialog, .number-picker-dialog-container .number-picker-dialog {
    display: block; }
    .date-picker-dialog-container .date-picker-dialog .action, .date-picker-dialog-container .number-picker-dialog .action, .number-picker-dialog-container .date-picker-dialog .action, .number-picker-dialog-container .number-picker-dialog .action {
      display: flex;
      justify-content: center;
      align-items: center; }
    .date-picker-dialog-container .date-picker-dialog input, .date-picker-dialog-container .number-picker-dialog input, .number-picker-dialog-container .date-picker-dialog input, .number-picker-dialog-container .number-picker-dialog input {
      text-align: center; }
  .date-picker-dialog-container .number-picker-dialog input, .number-picker-dialog-container .number-picker-dialog input {
    font-size: 32px; }

/* =============ProgressButton ================*/
.loading-button-wrapper {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px; }
  @media screen and (max-width: 991px) {
    .loading-button-wrapper {
      margin-top: 5px;
      margin-bottom: 4px; } }

.loading-button-progress {
  color: #4A90E2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px; }

/* =========== Utility ==================*/
.full-width {
  width: 100% !important; }

.half-width {
  width: 50% !important; }

.no-margin {
  margin: 0 !important; }

.hidden {
  display: none; }

.relative {
  position: relative !important; }

.flex {
  flex: 1; }

.flex-box {
  display: flex;
  flex-direction: row; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-row {
  flex-direction: row; }

.flex-column {
  flex-direction: column; }

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }
  .flex-end button {
    margin: 0 4px; }

.bottom-gutter {
  margin-bottom: 12px !important; }

.top-gutter {
  margin-top: 12px !important; }

.left-gutter {
  margin-left: 12px !important; }

.right-gutter {
  margin-right: 12px !important; }

/* =============Not found ================*/
.explain {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .explain .paper {
    width: 500px;
    padding: 24px;
    background-color: rgba(178, 202, 211, 0.5); }
    .explain .paper .explain-wrapper {
      display: flex; }
      .explain .paper .explain-wrapper .explain-box-left {
        display: flex;
        justify-content: center;
        align-items: center; }
        .explain .paper .explain-wrapper .explain-box-left .explain-icon {
          font-size: 72px;
          margin-right: 24px; }
      .explain .paper .explain-wrapper .explain-box-right {
        margin-left: 12px;
        color: #777777; }
        .explain .paper .explain-wrapper .explain-box-right h3 {
          font-weight: bold;
          color: #777777; }
        .explain .paper .explain-wrapper .explain-box-right p {
          margin: 0;
          line-height: 1.5; }
    .explain .paper .explain-action {
      display: flex;
      flex-direction: row-reverse; }
      .explain .paper .explain-action .button {
        margin-top: 12px; }
    .explain .paper .explain-icon {
      font-size: 72px;
      margin-right: 12px; }
  .explain .explain-box {
    width: 600px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 8px; }

.explain-with-header {
  height: calc(100vh - 64px); }

.explain-with-stepper {
  height: calc(100vh - 64px - 109px); }

/*===== map Reserve  ======*/
#svg-wrapper {
  height: calc(100% - 64px);
  padding-top: 64px; }
  @media screen and (max-width: 991px) {
    #svg-wrapper {
      height: calc(100% - 56px); } }
  @media screen and (max-width: 991px) {
    #svg-wrapper {
      padding-top: 56px; } }

.status-full {
  fill: #fb7271 !important; }

.status-available {
  fill: #4169e1 !important;
  animation: blink 1.5s ease-in-out infinite alternate; }

.status-commingsoon {
  fill: #ffa500 !important;
  animation: blink 1.5s ease-in-out infinite alternate; }

.status-unavailable {
  fill: #989898 !important; }
